import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default/DefaultTemplate.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Working With a Licensed Architect`}</h3>
    <Text as="p" mdxType="Text">
  Please note that the designers and drafters at HYH Studio work under the
  direct supervision of a licensed architect on all projects. For questions on
  process or more information, please contact us at{" "}
  <Link to="info@hyh.studio" mdxType="Link">info@hyh.studio</Link>.
    </Text>
    <h3>{`Copyrighted Material on Our Site`}</h3>
    <p>{`This website contains copyrighted material from `}<Link to="https://unsplash.com/" rel="noopener" target="_blank" mdxType="Link">{`unsplash.com`}</Link>{`, the use of which has been granted by Unsplash (granted irrevocable, nonexclusive, worldwide copyright license to download, copy, modify, and use photos from Unsplash).`}</p>
    <p>{`The photos shown on this website are not of actual projects of HYH Studio. The photos represent designs similar to work performed in the past and/or projects that HYH Studio can assist with by providing drafts and designs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      